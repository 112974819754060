<template>

<div>
    
    <b-modal hide-footer
      id="modalProduto"
      title="Consultar Produtos"
      size="xl"
    >
    <b-row>
      <b-col
        md="2"
        xl="2"
      >
        <b-form-group
          label="Filtrar Por:"
        >
          <b-form-select
            v-model="FiltroTela.filtrarPorSelected"
            :options="filtrarPorOptions">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        md="3"
        xl="3"
      >
        <b-form-group
          label="Valor:"          
        >
          <b-form-input
            id="filtrarPorValue"
            placeholder=""
            v-model="FiltroTela.filtrarPorValue"
            required
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

    <b-col
        md="6"
        xl="4"
    >
       <br>
       <div class="DivBotaoLadoLado" >
        <b-button  variant="primary" 
         type="button" @click="Consultar"><span>BUSCAR</span></b-button>
   
       </div>

    </b-col>
    </b-row>

     <b-card-code >
     <b-table
         striped
         hover
         responsive
         sticky-header
         :items="items"
         :fields="fields"
         :sort-by.sync="sortBy"
         :sort-desc.sync="sortDesc"
         :sort-direction="sortDirection"
         :filter="filter"
         :filter-included-fields="filterOn"
         table-class = "font-size11"
         thead-class = "font-size11"
         thead-tr-class = "font-size11"
         selectable
         @row-selected="Selected"
       >

       <template #cell(ativo)="row">
          {{  row.value = 'S' ?'Sim':'Não' }}
        </template>
      
      </b-table>
   
   </b-card-code>

   
    </b-modal> 


</div>
  
</template>

<script>
import {
  BModal, BButton, VBModal, BAlert, BCardText,
  BRow, BFormGroup,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormInput, BCol,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import FiltrosPortal from '@/customPortal/FiltrosPortal.vue'
import Spinner from '@/components/Spinner.vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'

export default {
  props: {
    ModalPRODUTO: Object,
  },
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BCardText,
    BFormGroup,
    BRow,
    BTable,
    BFormSelect,
    BFormInput,
    BPagination,
    BCardBody,
    BCol,
    
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      FiltroTela: {
        TitloTela: 'ModalProduto',
        Selecionado: false,
        filtrarPorValue: '',
        filtrarPorSelected: 'CODPROD',
        FiltroComplementar: [],
      },
      filtrarPorOptions: [
        { value: 'CODPROD', text: 'Codigo' },
        { value: 'REFERENCIA', text: 'Alternativo' },
        { value: 'AD_CODESTRAPOLO', text: 'Estruturado' },
        { value: 'DESCRPROD', text: 'Descrição' },
      ],
      perPage: 10,
      pageOptions: [3, 5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'codprod', label: 'Codigo',tdClass: 'td50'  },
        { key: 'referencia', label: 'Alternativo'  },
        { key: 'aD_CODESTRAPOLO', label: 'Estruturado'  },
        { key: 'descrprod', label: 'Produto',tdClass: 'td250' },
        { key: 'ativo', label: 'Ativo' },
      ],
      items: [
      ],
    }
  },
  methods: {
    async Consultar() {
      Spinner.StarLoad()
      let notification = ''

      const usuario = this.$session.get('usuario')
      const URLpost = `/skModalConsulta/ConsultaProduto?CODUSU=${usuario.codusu}&TIPO=${this.ModalPRODUTO.TIPO}`
      this.FiltroTela.FiltroComplementar = []
      
      let Filtro = {
        parametro: 'ATIVO',
        valor: this.ModalPRODUTO.ATIVO,
      }
      this.FiltroTela.FiltroComplementar.push(Filtro)


      //Filtro Complementar Apontamento Producao
      if(this.ModalPRODUTO.IDIPROC != null){
           let Filtro = {
           parametro: 'IDIPROC',
           valor: this.ModalPRODUTO.IDIPROC,
         }
         this.FiltroTela.FiltroComplementar.push(Filtro)
      }

      this.CarregaFiltroTela()

      const ParametroJson = JSON.parse(JSON.stringify(this.FiltroTela))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.items = response.data
          this.totalRows = this.items.length
        } else {
          notification = ConexaoAPI.MsgFiltroSemRetorno()
          AlertaMsg.FormataMsg(notification)
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    CarregaFiltroTela() {
      const usuario = this.$session.get('usuario')
      usuario.filtrosTela = FiltrosPortal.AdcionarFiltro(usuario, this.FiltroTela)
      this.$session.set('usuario', usuario)
    },
    Selected(item){
 
        if(item.length > 0)
        {

          if(item[0].codprod !== "" && item[0].codprod != undefined)
          {

            let ModalPRODUTO = this.ModalPRODUTO      
            ModalPRODUTO.CODPROD = item[0].codprod
            ModalPRODUTO.DESCRPROD = item[0].descrprod
            ModalPRODUTO.REFERENCIA = item[0].referencia
            ModalPRODUTO.AD_CODESTRAPOLO = item[0].aD_CODESTRAPOLO
            ModalPRODUTO.CODVOL = item[0].codvol

            this.$emit('SelectedModalProd',ModalPRODUTO)
            this.$emit('SetModalPRODUTO')
            this.$bvModal.hide('modalProduto');

          }
        }


    },
  }
}
</script>
